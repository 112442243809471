import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import GoogleMapReact from "google-map-react";
import "./contact.css";

const AnyReactComponent = ({ text }) => (
  <>
    <FontAwesomeIcon
      style={{
        fontSize: "2rem",
        color: "green",
        marginBottom: "0.3rem",
      }}
      icon={faMapMarkerAlt}
    />
    <span style={{ color: "green" }}>{text}</span>
  </>
);

const Map = (props) => {
  const defaultProps = {
    center: {
      lat: 50.012406,
      lng: 19.9757219,
    },
    zoom: 17,
  };

  function renderMarkers(map, maps) {
    return new maps.Marker({
      position: { lat: 50.012406, lag: 19.9757219 },
      map,
      title: "RAX!",
    });
  }

  return (
    <div style={{ height: "50vh", width: "100%" }}>
      <GoogleMapReact
        options={{
          styles: [
            {
              stylers: [{ saturation: 25 }],
            },
          ],
        }}
        bootstrapURLKeys={{ key: process.env.API_KEY }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
      >
        <AnyReactComponent lat={50.012406} lng={19.9757219} text="RAX" />
      </GoogleMapReact>
    </div>
  );
};

export default Map;
