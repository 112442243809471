import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import "./contact.css";

const ContactDetails = (props) => {
  const { site } = useStaticQuery(query);
  return (
    <div className="text-center addressee__info-content asign-left">
      {props?.children.length >= 1 && props.children[0]}
      <div className="details mb-3">
        <p>{site.siteMetadata.company}</p>
        <p>{site.siteMetadata.address}</p>
        <p>{`${site.siteMetadata.zip} ${site.siteMetadata.city}, ${site.siteMetadata.country}`}</p>
        <a href="/#">
          <p>{site.siteMetadata.email}</p>
        </a>
        <a href="/#">
          <p>{site.siteMetadata.number}</p>
        </a>
      </div>

      {props?.children.length >= 2 && props.children[1]}

      <div className="details mb-3">
        <p>{`KRS: ${site.siteMetadata.krs}`}</p>
        <p>{`NIP: ${site.siteMetadata.nip}`}</p>
        <p>{`REGON: ${site.siteMetadata.regon}`}</p>
        <p>{site.siteMetadata.address}</p>
        <p>{`${site.siteMetadata.zip} ${site.siteMetadata.city}, ${site.siteMetadata.country}`}</p>
      </div>
    </div>
  );
};

export default ContactDetails;

export const query = graphql`
  query ContactDetails {
    site {
      siteMetadata {
        company
        address
        email
        number
        zip
        city
        country
        krs
        nip
        regon
      }
    }
  }
`;
