// Use a little helper function to remove trailing slashes from paths
exports.removeTrailingSlash = (path) =>
  path === `/` ? path : path.replace(/\/$/, ``);

exports.localizedSlug = (
  { isDefault, locale, slug, type, category },
  prefix
) => {
  if (type === "page") {
    return isDefault ? `${slug}` : `/${locale}${slug}`;
  } else if (type === "blog") {
    return isDefault
      ? `/blog/${category}${slug}`
      : `/${locale}/blog/${category}${slug}`;
  } else {
    let newSlug = "";
    prefix.forEach((element) => {
      if (element[locale] && element.type === type) {
        newSlug = isDefault
          ? `/${element[locale]}${slug}`
          : `/${locale}/${element[locale]}${slug}`;
      }
    });
    return newSlug;
  }
};

// From lodash:
// https://github.com/lodash/lodash/blob/750067f42d3aa5f927604ece2c6df0ff2b2e9d72/findKey.js
exports.findKey = (object, predicate) => {
  let result;
  if (object == null) {
    return result;
  }
  Object.keys(object).some((key) => {
    const value = object[key];
    if (predicate(value, key, object)) {
      result = key;
      return true;
    }
    return false;
  });
  return result;
};

exports.findTemplate = (type, slug, languageMapping, locale) => {
  let template = null;

  if (type === "page") {
    languageMapping.forEach((element) => {
      if (element[locale] && element[locale].includes(slug)) {
        template = element.template;
      }
    });
  } else if (type === "case-studies") {
    template = "./src/templates/caseStudy.js";
  } else if (type === "career") {
    template = "./src/templates/jobOffer.js";
  } else if (type === "blog") {
    template = "./src/templates/post.js";
  }
  return template;
};
