import React from "react";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import LocalizedLink from "../LocalizedLink";

const locales = require(`../../../config/i18n`);

const { removeTrailingSlash } = require(`../../utils/gatsby-node-helpers`);
const Breadcrumbs = ({ crumbs }) => {
  const labels =
    crumbs.length > 0
      ? removeTrailingSlash(crumbs[crumbs.length - 1].pathname).split("/")
      : [];

  return (
    <nav className="container">
      <ol className="breadcrumbs">
        <li>
          <LocalizedLink to="/">
            <FontAwesomeIcon icon={faHome} />
          </LocalizedLink>
        </li>

        {labels.map((crumb, index) => {
          if (index === labels.length - 1)
            return (
              <li key={index} className="disabled">
                <Link to={crumbs[crumbs.length - 1].pathname}>
                  {crumbs[crumbs.length - 1].crumbLabel}
                </Link>
              </li>
            );
          return locales[crumb] !== undefined || crumb === "" ? null : (
            <li key={index} className="disabled">
              <LocalizedLink to={`/${crumb}`}>
                {crumb.replace("-", " ")}
              </LocalizedLink>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
