import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import useTranslations from "../useTranslations";
import "./contact.css";

const Call = () => {
  const { image, site, translate } = useStaticQuery(query);
  const { call } = useTranslations(translate);
  const img = getImage(image);

  return (
    <div className="row addressee__info-content asign-right">
      <div className="col-12 ">
        <div className="col-12 mb-5">
          <h2 className="f-fat">{call}</h2>
        </div>
        <GatsbyImage imgClassName="img-fluid" image={img} alt="call" />
        <div className="col-12 mt-1">
          <p className="pricing__tel">
            <a href="/#">
              <FontAwesomeIcon icon={faPhone} /> {site.siteMetadata.number}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Call;

const query = graphql`
  query CallQuery {
    image: file(relativePath: { eq: "call.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 960, placeholder: BLURRED, formats: [WEBP])
      }
    }
    translate: allFile(filter: { sourceInstanceName: { eq: "translations" } }) {
      edges {
        node {
          name
          translations: childTranslationsJson {
            call
          }
        }
      }
    }
    site {
      siteMetadata {
        number
      }
    }
  }
`;
