import React from "react";
import { graphql } from "gatsby";
import { useBreadcrumb } from "gatsby-plugin-breadcrumb";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Breadcrumbs from "../components/Navigation/breadcrumbs";

import ContactDetails from "../components/Contact/contactDetails";
import Seo from "../components/seo";
import WriteUs from "../components/Contact/writeUs";
import Call from "../components/Contact/call";
import Map from "../components/Contact/map";

const Contact = ({ path, location, data: { mdx } }) => {
  const { crumbs } = useBreadcrumb({
    location,
    crumbLabel: mdx?.frontmatter?.title,
  });

  return (
    <>
      <Seo
        title={mdx.frontmatter.title}
        description={mdx.frontmatter.description}
        pathname={path}
      />
      <Breadcrumbs crumbs={crumbs} />

      <div className="container-fluid mb-5">
        <div className="row">
          <div className="col-lx-3 col-lg-3 col-12">
            <MDXProvider
              components={{
                ContactDetails,
              }}
            >
              <MDXRenderer frontmatter={mdx.frontmatter}>
                {mdx.body}
              </MDXRenderer>
            </MDXProvider>
          </div>
          <div className="col-lx-6 col-lg-6 col-12">
            <WriteUs />
          </div>
          <div className="col-lx-3 col-lg-3 col-12">
            <Call />
          </div>
        </div>
        <div className="row mt-5">
          <Map />
        </div>
      </div>
    </>
  );
};
export default Contact;

export const query = graphql`
  query Contact($locale: String!, $slug: String!) {
    mdx(fields: { locale: { eq: $locale }, slug: { eq: $slug } }) {
      frontmatter {
        title
      }
      body
    }
  }
`;
