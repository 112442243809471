import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import useTranslations from "../useTranslations";
import "./contact.css";

const WriteUs = (props) => {
  const { translate, site } = useStaticQuery(query);
  const { write_us } = useTranslations(translate);

  return (
    <div className="addressee__info-content ">
      <div className="col-12">
        <span className="write-to-us">
          <span className="f-light text-center">{write_us}</span>
        </span>
      </div>

      <div className="col-12">
        <a href="/#">
          <p className="email-form">{site.siteMetadata.email}</p>
        </a>
      </div>
    </div>
  );
};

export default WriteUs;

const query = graphql`
  query WriteUs {
    translate: allFile(filter: { sourceInstanceName: { eq: "translations" } }) {
      edges {
        node {
          name
          translations: childTranslationsJson {
            write_us
          }
        }
      }
    }
    site {
      siteMetadata {
        email
      }
    }
  }
`;
